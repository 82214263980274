.checkout-signup-form-wrapper {
  overflow-x: hidden;
}

.checkout-signup-form {
  background-color: #ffffff;
  border-radius: unset;
  box-shadow: none;
  box-sizing: unset;
  font-family: 'CircularStd Book';
  font-size: 11px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 460px;
  margin: 4px auto 4rem auto;
}

.checkout-signup-form .checkout-form-head-container {
  padding: 16px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
}

.checkout-signup-form h5 {
  color: #4d4d4d;
  float: left;
  font-family: 'CircularStd Book';
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  margin: 0 0 10px 0;
  text-align: center;
}

.checkout-signup-form .inp-field input {
  border: 0 none;
}

.checkout-signup-form .inp-field:last-child {
  border-bottom: 0 none;
}

.checkout-forgot-password-link {
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 2rem;
  margin-bottom: 10px;
}

.checkout-forgot-password-link p {
  color: #999999;
  display: inline;
  margin: 0 0 5px 0;
}

.checkout-signup-button {
  margin-top: 2rem;
}

.checkout-signup-button button {
  background-color: #69d27c !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  width: 266px !important;
}

.checkout-signup-button button > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 266px;
  }

.checkout-signup-link {
  margin: 0 5px;
}

.checkout-signup-checkbox {
  margin: 2rem auto 0 auto;
  width: max-content;
}

.checkout-signup-checkbox input,
.checkout-signup-checkbox svg {
  height: 20px !important;
  width: 20px !important;
}

.checkout-signup-checkbox svg {
  left: -4px !important;
}

.checkout-signup-checkbox-wrapper {
  padding: 0 1.5rem;
}

.checkout-signup-checkbox-label {
  color: #4d4d4d !important;
  font-family: 'CircularStd Book' !important;
  font-size: 18px !important;
  font-weight: 300 !important;
  letter-spacing: 0 !important;
  line-height: 16px !important;
}

.checkout-signup-checkbox-label::before {
  height: 20px !important;
  width: 20px !important;
}

@media (max-width: 900px) {
  .checkout-form-container {
    height: 100%;
    width: 100%;
  }

  .checkout-signup-form-wrapper {
    height: inherit;
    width: inherit;
  }

  .checkout-signup-form {
    border-radius: 0;
    height: 100% !important;
    margin: 0;
    overflow: scroll;
    width: inherit;
  }

  .checkout-signup-form h5 {
    padding-bottom: 0.5rem;
    width: 100%;
  }

  .checkout-forgot-password-link {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 150px;
  }

  .checkout-forgot-password-link p {
    display: block;
  }

  .checkout-signup-button button {
    background-color: #69d27c !important;
    height: 62px !important;
    width: 266px !important;
  }

  .checkout-signup-button button > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 266px;
  }
}

.checkout-tabSelected {
  float: left;
  margin: 5px;
  color: #9b63f8;
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 23px;
}

.checkout-p-sign-up {
    width: 240px;
}