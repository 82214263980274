.relive-page {
  background-color: #181818;
  display: flex;
  flex: 1;
}

.relive-page-loader {
  margin: 5rem auto;
}

.relive-page-error {
  color: #FFF;
  font-size: 16px;
  margin: 5rem auto;
}

@media(max-width: 1224px) {
  .relive-page {
    height: 100vh;
  }
}
