.animate-bar {
  display: flex;
  opacity: 1;
  top: 0;
}

.left-container-bar {
  display: flex;
  flex: 1;
  padding-left: 1rem;
}

.col-right-bar {
  display: flex;
  width: 27rem;
}

.right-content-btn {
  margin: auto;
}

.content-right-bar {
  margin-right: 1rem;
}

.media-content-inner {
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: start;
}

.experience-single .exp-bottom .media-content {
  flex: 1;
  float: left;
  margin-left: 19px;
  text-align: start;
}

.experience-single .exp-bottom .media-content h4 {
  font: 24px/26px 'CircularStd Medium';
  margin: 0px;
}

.experience-single .exp-bottom .media-content span {
  font-size: 16px;
  color: #9b9b9b;
  margin: 0px;
}
