.ticket-header-mob {
  background-color: #F5F5F5;
  padding: 0;
  margin-bottom: 0px;
}

.ticket-header-image-mob {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  height: 5rem;
}

.ticket-header-icon-wrapper-mob {
  background: none;
  border: none;
  cursor: pointer;
  height: min-content;
  margin: auto 0 auto 1rem;
  padding: 0;
}

.ticket-header-info-mob {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  width: 100%;
  max-width: 860px;
  margin: 0 auto;
}

.ticket-header-info-mob p {
  margin: .5rem 0 0 0;
}

.ticket-header-info-name-mob {
  margin: 0;
  color: #000000;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 28px;
}

.ticket-header-info-channel-mob {
  color: #999999;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

.ticket-header-info-date-mob {
  color: #4D4D4D;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
}
