
.guidelines-mobile-container {
  background-color: #F5F5F5;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: scroll;
}

.guidelines-mobile-wrapper {
  flex: 1;
  overflow-y: scroll;
}

.guidelines-mobile-info {
  margin: 2rem 1rem 4rem 1rem;
}

.guidelines-mobile-title h3 {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 30px;
}

.guidelines-mobile-title > h3:first-child {
  color: #000;
}

.guidelines-mobile-title > h3:last-child {
  color: #3C2784;
}

.guidelines-dividor {
  box-sizing: border-box;
  height: 3px;
  width: 99px;
  border: 2px solid #3C2784;
}

.guidelines-mobile-list > li:last-child {
  border-bottom: 1px solid #EBEBEB;
}

.guidelines-mobile-item-wrapper {
  border-top: 1px solid #EBEBEB;
  padding: 1rem 0;
}

.guidelines-mobile-item {
  color: #626262;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 25px;
}

.guidelines-button-mobile {
  border-radius: 0;
  height: 3.9rem;
  width: 100%;
}

.guidelines-button-mobile-wrapper {
  padding: 0 1rem;
}

.guidelines-footer-mobile {
  width: 100%;
  background-color: #fff;
  padding: 24px 0;
  box-sizing: border-box;
  -webkit-box-shadow: 0 -7px 26px 0 rgba(0,0,0,0.09);
  -moz-box-shadow: 0 -7px 26px 0 rgba(0,0,0,0.09);
  box-shadow: 0 -7px 26px 0 rgba(0,0,0,0.09);
  z-index: 999;
}

.guidelines-footer-mobile .btn-wrap-mob {
  box-sizing: border-box;
  font-size: 20px;
  color: #fff;
}

.guidelines-footer-mobile .btn-wrap-mob a {
  display: block;
}

.guidelines-footer-mobile .ticket-btn-mob a {
float: none;
color: #fff;
}
