.guestlists-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.guestlists-tab-label {
  color: #999999;
  font-family: "CircularStd Book";
  font-size: 18px;
  font-weight: 300;
  margin-right: 1.5rem;
  transition: all .5s;
}

.guestlists-tab-label :first-child {
  margin-right: 5px;
}

.guestlists-tab-label :last-child {
  color: #999999;
}

.guestlists-tab-label-selected {
  color: #000;
  border-bottom: solid 2px #000;
  padding-bottom: 5px;
}

.guestlists-tab-label-selected :last-child {
  color: #626262;
}

.guestlists-download-button {
  color: #4D4D4D;
  cursor: pointer;
  border: 1px solid #D5D5D5;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.08);
  font-family: "CircularStd Book";
  font-size: 16px;
  font-weight: 300;
  padding: 7px .8rem;
  text-align: center;
  transition: all .1s;
}

.guestlists-download-button:active {
  opacity: .5;
}
