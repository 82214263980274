.button-all {
  cursor: pointer;
}

.button-green {
  background-color: #69D27C;
  border: 0;
  border-radius: 8px;
  color: #FFFFFF;
  font-family: "CircularStd Bold";
  font-size: 14px;
  letter-spacing: 1.24px;
  line-height: 25px;
  opacity: 1;
  text-align: center;
  transition: 2ms;
}

.button-transparent {
  background: transparent;
  border: 2px solid #D5D5D5;
  border-radius: 17.5px;
  color: #4D4D4D;
  font-family: "CircularStd Book";
  font-size: 16px;
  font-weight: 300;
  padding: .4rem 1rem;
}

.button-purple-square {
  background-color: #9B63F8;
  border: 0;
  border-radius: 8px;
  color: #FFFFFF;
  font-family: "CircularStd Book";
  font-size: 16px;
  font-weight: 300;
  padding: .4rem 1rem;
}

.button-green:hover,
.button-green:active {
  opacity: 0.5;
}

.button-transparent:active, 
.button-transparent:hover {
  opacity: 0.7;
}

.button-purple-pill {
  border-radius: 26.5px;
  background-color: #9B63F8;
  border: 0;
  color: #FFFFFF;
  font-family: "CircularStd Book";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}
