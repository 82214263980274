.snackbar {
  background-color: rgba(255, 0, 13, 0.88);
}

.snackbar-info {
  background-color: #69d27c;
}

.card {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.04);
}

.card-category {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 30px;
  margin-top: 10px;
  min-height: 260px;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.04);
}

.label {
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 25px;
  margin-bottom: 10px;
}

.urlslug-wrapper {
  background-color: #f3f3f4;
  display: flex;
  align-items: baseline;
  width: 90%;
  margin: auto;
  border-radius: 8px;
  border: 1px solid transparent;
}

.urlslug-wrapper:focus-within {
  border: 1px solid rgba(155, 99, 248, 0.33) !important;
  box-shadow: 0px 0px 0px 3px rgba(155, 99, 248, 0.1) !important;
  background-color: #ffffff;
}

.urlSlug-label {
  margin: auto;
  padding-left: 20px;
  font-size: 18px;
}

#urlSlug {
  padding-left: 5px;
  border: 1px transparent;
}

#urlSlug:focus {
  border: none !important;
  box-shadow: none !important;
  background: none;
}

h3 {
  font-size: 20px;
  margin-top: 10px;
}

p {
  color: #4d4d4d;
  font-family: 'CircularStd Book';
  font-size: 15px;
  font-weight: 50;
  letter-spacing: 0;
  line-height: 20px;
}

@media(max-width: 640px) {
  .urlSlug-label {
    display: none;
  }

  #urlSlug {
    padding-left: 15px;
  }
}
