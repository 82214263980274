.add-tickets-button {
  margin-right: .5rem;
}

.free-label {
  width: max-content;
  color: #C0C0C0;
  font-weight: bold;
  padding: 1rem 2rem;
  border: 2px solid #D5D5D5;
  border-radius: 8px;
  line-height: 28px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1px;
}

.ticket-field-label {
  margin-left: 5px;
}

.field-title-container {
  flex: 0.95 !important;
}

.settings-button-wrapper {
  display: flex;
  flex: 0 !important;
}

.button-icon-wrapper {
  background: none;
  border: none;
  cursor: pointer;
  height: min-content;
  margin-bottom: auto;
  margin-left: .5rem;
  margin-top: auto;
  padding: 15px 0 0 0;
}

.ticketing-dialog-body .description {
  border-bottom: 1px solid #EBEBEB;
  padding: 18px;
}

.ticketing-dialog-body .visibility {
  align-items: center;
  border-bottom: 1px solid #EBEBEB;
  display: flex;
  justify-content: space-between;
  padding: 18px;
}

.ticketing-dialog-body span {
  color: #999999;
  font-family: 'CircularStd Bold';
  font-size: 11px;
  font-weight: bold;
}

.ticketing-dialog-body .description span {
  display: block;
}

.ticketing-dialog-body textarea,
.ticketing-dialog-body input {
  border: none;
  color: #838383;
  font-family: 'CircularStd Book';
  font-size: 14px;
  height: 60px;
  outline: none;
  margin-top: 10px;
  width: 100%;
}

.ticketing-dialog-body input {
  height: unset;
}

.ticketing-dialog-body textarea::placeholder,
.ticketing-dialog-body input::placeholder {
  color: #AEAEAE;
  font-family: 'CircularStd Book';
  font-size: 14px;
  text-transform: none;
}

.ticketing-dialog-body .visibility-toggle {
  width: 66px !important;
}

.ticketing-dialog-body .visibility-toggle > div:nth-child(2) > div {
  margin: 0 !important;
  padding: 6px 0 !important;
  width: 66px !important;
}


.ticketing-dialog-body .tickets-buttons-container {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 18px;
}

.ticketing-dialog-body .tickets-buttons-container > button {
  align-items: center;
  background: none;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  font-family: 'CircularStd Book';
  font-size: 14px;
  font-weight: 300;
  justify-content: center;
  min-width: 84px;
  padding: 8px 12px;
}

.ticketing-dialog-body .tickets-buttons-container > button > svg {
  margin-left: 8px;
}

.ticketing-dialog-body .tickets-buttons-container > button:first-child {
  border: 1px solid #EBEBEB;
  color: #626262;
}

.ticketing-dialog-body .tickets-buttons-container > button:last-child {
  background-color: #9B63F8;
  border: none;
  color: #FFFFFF;
  margin-left: 12px
}
