.header-mobile {
  padding: 14px 16px 7px 6px;
  box-shadow: 0 0 6px rgba(0,0,0,0.2);
  position: fixed;
  right: 0px;
  left: 0px;
  top: 0px;
  width: 100%;
  box-sizing: border-box;
  z-index:1000;
  background-color:#fff;
}

.header-mobile-center {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.header-mobile-logo {
  display: block;
  margin-top: 10px;
  height: 23px;
  width: 90px;
}

.title-page-mob{
  font-size: 23px;
  margin-top: 10px;
}

.header-mobile-channel-name {
  font-family: "CircularStd Book";
  font-size: 18px;
  font-weight: 300;
  line-height: 48px;
}
