.snackbar-root {
  margin-bottom: 20px;
}

.snackbar-container {
  background-color: #3C2784;
  border: 2px solid #52418a;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 99px;
  width: 411px;
}

.snackbar-header {
  display: flex;
  justify-content: flex-end;
}

.snackbar-close-icon-wrapper {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  margin: .7rem .5rem .4rem .5rem;
}

.snackbar-close-icon {
  height: 14px;
  width: 14px;
}

.snackbar-body {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  padding: 0 2rem;
}

.snackbar-image-wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
}

.snackbar-image-wrapper img {
  margin-top: 0.6rem;
}

.snackbar-title {
  color: #FFF;
  margin: 0;
}

.snackbar-description {
  color: #AFB1CF;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 21px;
  margin: 0;
}

@media(max-width: 900px) {
  .snackbar-container {
    border:none;
    height: 110px;
    width: 100%;
  }
}
