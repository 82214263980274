.main-page-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.main-page-channel-list-experiences {
  padding-bottom: 2rem;
}

.main-page-channel-list-experiences-tail {
  padding-bottom: 2rem;
  padding-top: 0 !important;
}

.main-page-channel-list-experiences-past {
  padding-bottom: 2rem;
  padding-top: 2rem !important;
}

.experience-list-component {
  display: grid;
  grid-gap: 12px;
  width: 900px;
}

.date-title {
  height: 104px;
  width: 860px;
  /* padding: 0 20px; */
  box-sizing: border-box;
  display: grid;
  /* grid-auto-flow: column;
  grid-auto-columns: auto; */
  align-items: center;
}


@media screen and (max-width: 1224px) {
  .experience-list-component {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 860px;
  }

  div.schedule-container--empty {
    width: 13px;
    flex: 0 0;
    margin-right: 15px;
  }

  .schedule-container {
    display: flex;
    flex: 1 0 0;
  }

  .schedule-title {
    width: auto;
  }

  .date-title {
    width: 100%;
    max-width: 860px;
  }

  .date-title--empty {
    display: flex;
    justify-content: flex-start;
  }
}

.section-slideshow {
  padding-bottom: 60px;
}

.section-slideshow .bx-viewport,
.section-slideshow .slide-bg {
    height: 655px !important;
}

.section-slideshow .slide-bg {
  position: absolute;
  width: 100%;
}


.load-more-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F5F5;
    padding: 20px 0;
}
