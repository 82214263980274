.date-wrapper {
  border: 2px solid #fff;
  border-radius: 8px;
  float: left;
  font-size: 14px;
  margin-right: 14px;
  padding: 0px 9px 8px 9px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}

.date-wrapper-checkout {
  border: 2px solid #A572F9;
  color: #000000;
}

.date-wrapper .date-num {
  font-size: 28px;
  line-height: 20px;
  display: block;
  margin-top: -1px;
}

.time-wrapper {
  font-size: 20px;
  line-height: 24px;
  padding-top: 8px;
}

.time-wrapper h4 {
  margin: 0;
}

.datetime-wrapper-checkout {
  margin-left: 12rem;
  margin-bottom: 38px;
}

.datetime-wrapper-checkout .date-wrapper {
  color: #000000;
}

.datetime-wrapper-checkout .time-wrapper {
  color: #000000;
}
