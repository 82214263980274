@font-face {
  font-family: "futuraStd medium";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/futurastd-medium-webfont.eot"), url("../fonts/futurastd-medium-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/futurastd-medium-webfont.otf") format("opentype"), url("../fonts/futurastd-medium-webfont.woff") format("woff"), url("../fonts/futurastd-medium-webfont.ttf") format("truetype"), url("../fonts/futurastd-medium-webfont.svg#futurastd-medium-webfont") format("svg"); 
}

body {
  margin: 0;
  font: 20px/30px "futuraStd medium", sans-serif;
}


/* this styles are here to apply some changes to material-ui Menu (v.0.20) */
body > div:not(.intercom-lightweight-app):not(#app) > div:not(.MuiBackdrop-root) {
  border-radius: 8px !important;
  margin-top: 40px;
}

body > .MuiDialog-root .MuiPickersToolbar-toolbar {
  background-color: #9B63F8;
}

body > .MuiDialog-root .MuiPickersDay-daySelected {
  background-color: #9B63F8;
}

body .MuiPickersClockPointer-pointer .MuiPickersClockPointer-thumb {
  border-color: #9B63F8;
}

body .MuiPickersClockPointer-pointer {
  background-color: #9B63F8;
}

body .MuiPickersClock-pin {
  background-color: #9B63F8;
}

body .MuiPickersModal-dialogRoot .MuiButton-textPrimary span {
  color: #9B63F8;
}