.experience-list .experience-item .ticket-pricing small,
.experience-list .experience-item .ticket-guest small {
    display: block;
    color: #bfbfbf;
    font-size: 13px;
}

.experience-list .experience-item .exp-block {
  display: table-cell;
  vertical-align: middle;
  width: 15%;
}

.ticket-guest {
  display: table-cell;
  vertical-align: middle;
  width: 10%;
}

.experiences-panel-button {
  display: table-cell;
  vertical-align: middle;
}

.experience-list .edit-dropdown {
  font-size: 13px;
  background-color: #ededed;
  float: right;
  border-radius: 8px;
  line-height: 42px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  height: 40px;
  display: flex;
}

.open-in-new-button {
  color: #a2a2a2;
  display: inline-block;
  border-right: 1px solid #fff;
  text-transform: capitalize;
}

.open-in-new-icon {
  height: 17px;
  width: 17px;
  color: #B9B9B9;
}

.experience-list .edit-dropdown #draft {
  padding: 12px 19px;
  color: #a2a2a2;
  display: inline-block;
  border-right: 1px solid #fff;
}

.experience-list .edit-dropdown #status {
  padding: 0 18px;
  color: #a2a2a2;
  display: inline-block;
  border-right: 1px solid #fff;
  text-transform: capitalize;
  height: 40px;
}

.experience-list .edit-dropdown .manage-dropdown {
  float: left;
  position: relative;
}

.experience-list .edit-dropdown .manage-dropdown .btn-gray {
  display: block;
  width: 43px;
  height: 39px;
}

.experience-list .edit-dropdown .manage-dropdown .opt-dropdown {
  text-indent: -9999px;
  display: block;
  width: 100%;
  height: 100%;
  background-position: -3px -84px;
}

.experience-list .edit-dropdown .manage-dropdown .manage-exp-dropdown {
  text-align: left;
  border: 1px solid #ddd;
  z-index: 99;
  padding: 22px 13px;
  top: 0;
  right: -4px;
}

.experience-list .edit-dropdown .manage-dropdown .manage-exp-dropdown:after {
  display: none;
}

.experience-list .edit-dropdown .manage-dropdown .manage-exp-dropdown li {
  margin-top: 21px;
}

.experience-list .edit-dropdown .manage-dropdown .manage-exp-dropdown li:first-child {
  margin-top: 0;
}

.experience-list .edit-dropdown .manage-dropdown .manage-exp-dropdown li a {
  font-size: 15px;
  color: #000;
}
