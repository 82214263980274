.body1{
  font-size: 14px;
  font-family: 'CircularStd Book';
  line-height: 18px;
  font-weight: normal;
  color: #626262;
}


.h2{
  font-size: 18px;
  font-family: 'CircularStd Book';
  line-height: 21px;
  color: #000000;
}


.h4{
  font-size: 11px;
  font-weight: 500;
  color: #B4B4B4;
  font-family: 'CircularStd Book';
}