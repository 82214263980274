.checkout-form-mobile{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.checkout-fields-wrapper-mobile {
  flex: 1;
}

.checkout-fields-mobile {
  padding: 0 1.5rem;
}
