.signup-container {
  overflow: scroll;
}

@media(max-width: 900px) {
  .signup-header {
    display: none;
  }

  .signup-container {
    overflow: hidden;
  }
}
