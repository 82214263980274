.artist-column-right {
  flex: 1;
}

.artist-column-left {
  flex: 1;
}

.artist-content {
  margin-left: 4%; 
  margin-right: auto;
  width: 604px;
}

.artist-column-left :first-child {
  margin-left: auto;
  margin-right: 4%;
  width: 604px;
  font-size: 38px;
}

.artist-title {
  display: flex;
}

.artist-wrapper {
  display: flex;
  padding-left: 0;
  padding-right: 0;
}

@media(max-width: 1200px) {
  .artist-column-right {
    width: 100%;
    overflow: hidden;
  }
  
  .artist-column-left {
    width: 100%;
    float: left;
    margin-bottom: 4%;
  }

  .artist-column-left > :first-child {
    margin-left: 4%;
  }

  .artist-wrapper {
    display: flex;
    flex-direction: column;
  }
  .artist-title {
    display: flex;
    flex-direction: column;
  }
}
