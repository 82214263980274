.main-page-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.main-page-list-experiences {
  padding-top: 2rem !important;
  padding-bottom: 2rem;
}

.section-slideshow {
  padding-bottom: 60px;
}

.section-slideshow .bx-viewport,
.section-slideshow .slide-bg {
    height: 655px !important;
}

.section-slideshow .slide-bg {
    position: absolute;
    width: 100%;
}


.load-more-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F5F5F5;
  padding: 20px 0;
}