.avatar{
  float: left;
  margin-right: 10px;
}

.content{
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.06);
  padding: 0 0 30px 0;
}

table{
  width: 100%;
  border-collapse: collapse;
  padding-bottom: 36px;
  font-family: 'CircularStd Book';
}

th {
  font-size: 11px;
  font-weight: 500;
  color: #B4B4B4;
  text-align: left;
  line-height: 38px;
  padding-top: 3px;
}

td {
    font-size: 14px;
    line-height: 18px;
    color: #626262;
    border-bottom: 1px solid rgb(224, 224, 224);
    padding: 15px 0 14px 0;
}

td:first-child, th:first-child {
  padding-left: 38px;
  line-height: 24px;
}
td:last-child, th:last-child {
  padding-right: 4px;
}

.spinner{
  text-align: center;
  width: 100%;
  padding-top: 50px;
}

.tac{
  text-align: center;
}

.others {
  position: relative;
  display: inline-block;
  padding-left: 2px;
  color: #999;
  cursor: default;
  text-decoration: underline;
}

.others:hover .tooltip{
  visibility: visible;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #9B63F8;
  border-radius: 6px;
  padding: 10px;
  left: 100%;
  top: -5px;
  transform: translateX(calc(-50% - 20px)) translateY(-100%);
  white-space: pre;
}

.tooltip span {
  color: #fff;
}

.tooltip::after{
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #9B63F8 transparent transparent transparent;
}


.discount-value {
  color: #d5d5d5;
}

.discount-code {
  color: #999;
}

.total {
  color:#3BB050;
}
